@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap");
.headerContainer {
  text-align: right;

  /* color: aqua; */
}
.settingsValue {
  background: linear-gradient(to right, #084cb3, #084cb3, #fff);
  padding-right: 30px;
  stroke: 2px solid #fff;
  width: 100%;
  text-transform: uppercase;
  position: fixed;
  z-index: 101;
}
.headerContainer h3 {
  /* font-family: "Montserrat" sans-serif; */
  font-size: 20px;
  /* color: #0d3166; */
  color: #084cb3;
  font-family: "Akaya Telivigala", cursive;
  top: 0;
  margin: 0;
  padding: 3px 0px;
}

.headerBar {
  width: 100%;
  display: flex;
  position: fixed;
  top: 29px;
  justify-content: space-around;
  background-color: transparent;
  padding: 5px;
  z-index: 100;
}
.headerVisColor {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}
.block .navItem .naveItemHover a {
  color: #084cb3;
}
.navItem .naveItemHover a:hover {
  border-bottom: transparent;
  transform: scale(1.1);
}
.actveMenu {
  border-bottom: 2px solid #084cb3;
  font-weight: bold;
}

.whiteActiveMenu {
  border-bottom: 2px solid white;
  font-weight: bold;
}

.header {
  width: 90%;
  margin: 0 auto;
  display: flex;
}
.mobile {
  position: absolute;
  right: 30px;
  color: white !important;
  display: none;
}

.mobile button {
  background: white;
  border: none;
  outline: none;
}

/*menu header*/

.menu {
  width: 100%;
  color: black;
  margin: 0 auto;
  background-color: transparent;
  display: flex;
  justify-content: space-around;
}
.subMenu {
  margin: 0 auto;
  display: block;
  font-size: 15px;
  margin: 0 auto;
  position: absolute;
  top: 50%;

  transform: translateY(-50%);
}
.navItem {
  width: 150px;
  font-size: large;
  text-align: center;
}
.naveItemHover {
  padding: 4px 4px;
}

.FlexNon {
  display: flex;
  padding: auto;
}
.blue {
  color: #084cb3;
}
.white {
  color: white;
}
.white:hover {
  color: white;
}
.blueImageSmall {
  display: none;
}
@media (max-width: 1300px) {
  .blueImageSmall img {
    margin-right: 90%;
  }
  .settingsValue {
    background: linear-gradient(to right, #084cb3, #fff);
  }
  .logoBigImage {
    display: none;
  }
  .blueImageSmall {
    display: block;
  }
  .headerBar {
    background-color: white;
  }
  .mobile {
    display: block;
    z-index: 1;
  }
  .subMenu {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 700px;
    margin: auto;
    background-color: white;
    opacity: 0.95;
    padding-top: 30px;
    position: absolute;
    top: 0%;
    transform: translateY(-0%);
  }
  .header {
    display: block;
  }

  .navItem {
    width: 350px;
    padding: 20px 0px;
    color: white;
  }
  .FlexNon {
    display: none;
  }
  .menu {
    height: 0px;
  }
  .menu .subMenu .navItem .naveItemHover a {
    /* color: white; */
    font-size: large;
    font-weight: bold;
  }
  .closebigIcon {
    position: relative;
    top: 12px;
    border-radius: 10px;
    background: #084cb3;
  }
}
@media (max-width: 575px) {
  .headerContainer h3 {
    font-size: 13px;
    padding: 10px;
  }
  .mobile {
    right: 0px;
    top: 0px;
    margin: 3px;
  }
  .headerBar {
    margin-top: 0px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .menu {
    height: 0px;
  }
}

.LogoClose {
  visibility: hidden;
}

.closeOpenIcon {
  border: none;
  outline: none;
}

.closebigIcon {
  position: relative;
  top: 13px;
  border-radius: 10px;
  background: #084cb3;
}
