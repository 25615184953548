.imagePharma {
  width: 100%;
}
.imagePharma img {
  width: 100%;
}

.farmaContainer {
  width: 80%;
  margin: 10px auto;
}
.farmaContainer h2 {
  width: 100%;
  margin: 10px auto;
  color: #0d3166;
  font-size: 18px;
  line-height: 2;
  font-weight: normal;
  text-transform: uppercase;
}
.pharmaBody .farmaContainer h1 {
  width: 50%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 50px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.pharmaText {
  color: #fff;
  background-color: #0d3166;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px;
  border-top: 1px solid #0d3166;
  border-bottom: 1px solid #0d3166;
}
.formWhite {
  width: 70%;
  margin: 30px auto;

  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 4%;
}

.inputPharma {
  width: 70%;
  height: 50px;
  border-radius: 5px;
  margin: 10px auto;
  border: none;
  background-color: #fff;
  border-bottom: 2px solid gray;
  outline: none;
}

.inputAera {
  width: 70%;
  height: 150px;
  border-radius: 5px;
  margin: 10px auto;
  border: none;
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  outline: none;
}

.btanCarer {
  position: relative;
  color: white;
  text-decoration: none;
  margin-bottom: 50px;
}
.btn.btanCarera {
  z-index: 1;
  position: relative;
  color: white !important;
}

.btanCarer span {
  transition: all 0.3s;
}
.btanCarer::before {
  content: "Submit";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #0d3166;
  border-bottom-color: #0d3166;
  transform: scale(0.1, 1);
  color: #0d3166;
}
.btanCarer:hover span {
  letter-spacing: 2px;
}
.btanCarer:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.btanCarer::after {
  content: "Submit";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: #0d3166;
}
.btanCarer:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
  color: white;
}

@media (max-width: 1400px) {
  .formWhite {
    width: 100%;
  }
  .farmaContainer h2 {
    width: 90%;
  }

  .pharmaBody .farmaContainer h1 {
    font-size: 30px;
  }
}
@media (max-width: 972px) {
  .formWhite {
    width: 100%;
  }
  .farmaContainer {
    width: 90%;
  }

  .pharmaBody .farmaContainer h1 {
    font-size: 30px;
  }
  .inputPharma,
  .inputAera {
    width: 90%;
  }
}
@media (max-width: 615px) {
  .farmaContainer {
    width: 100%;
  }
  .pharmaBody .farmaContainer h1 {
    font-size: 20px;
    width: 60%;
  }
  .imagePharma img {
    margin-top: 100px;
  }
}
