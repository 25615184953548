.detContainer img {
  width: 100%;
}

.imageDetails {
  margin: 30px auto;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 4%;
  padding: 2%;
  position: absolute;
  right: 20px;
}
.imageDetails img {
  width: 400px;
  height: 400px;
}
.detailsContainer {
  width: 95%;
  margin: 10px auto;
}

.titleDetails {
  width: 20%;
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 25px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.defDetails {
  width: 60%;
  color: #0d3166;
  line-height: 2;
  text-transform: uppercase;
  margin-right: 20px;
}

@media (max-width: 1155px) {
  .defDetails {
    width: 65%;
  }
  .imageDetails img {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 1037px) {
  .defDetails {
    width: 55%;
  }
}
@media (max-width: 800px) {
  .defDetails {
    width: 100%;
  }
  .imageDetails {
    position: relative;
    right: 0;
  }
  .imageDetails img {
    width: 250px;
    height: 250px;
  }
}
