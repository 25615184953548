.form {
  background-color: white;
  align-items: center;
  text-align: center;
  display: block;
  width: 25%;
  margin: 0 auto;
  border-radius: 5px;
}
.LogIn {
  width: 100%;
  height: 700px;
  margin-top: 200px;
}

.inputAuth {
  margin-bottom: 30px;
}
.headForm {
  margin-bottom: 60px;
}
