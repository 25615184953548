.footerContainer {
  margin-top: 200px;
  width: 100%;
  height: 600px;
  background-color: white;
  font-family: "Montserrat", sans-serif;

  position: relative;
  /* z-index: 2; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19); */
}
.subFooter {
  width: 100%;
  height: 450px;
  border-radius: 0px 50% 0px 0px;
  position: absolute;
  /* z-index: 3; */
  bottom: 0;
  background-color: #084cb3;
  color: white;
  display: flex;
  padding-top: 80px;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  /* border: 2px solid #c7b746; */
  box-shadow: 0px 10px 30px 0px black inset;
}

.FootercontactUs {
  text-align: start;
}

.inputProblem {
  width: 250px;
  height: 45px;
  border-radius: 2px;
  margin-right: 5px;
}

.copyRight {
  width: 90%;
  border-top: 2px solid white;
  margin: 0px auto;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
  left: 5%;
  padding: 20px;
  z-index: 2;
}

.FooterFollowUs {
  text-align: center;
  display: block;
}
.socialMedia {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-right: 20px;
}
.FooterFollowUs a {
  color: white;
}
.footerLogo {
  position: relative;
  margin-top: 100px;
}

@media (max-width: 1100px) {
  .footerContainer {
    height: 950px;
  }
  .subFooter {
    height: 850px;
  }
  .footerLogo {
    margin-bottom: 300px;
  }
}
@media (max-width: 480px) {
  .footerContainer {
    height: 1000px;
  }
  .subFooter {
    height: 1050px;
    border-radius: 0px 30% 0px 0px;
  }
}
