.healthHeader h1 {
  width: 50%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .healthHeader h1 {
    font-size: 40px;
  }
}
@media (max-width: 615px) {
  .healthHeader h1 {
    font-size: 20px;
  }
  .healthHeader img {
    margin-top: 100px;
  }
}
