.textEaraContainer {
  display: block;
  width: 80%;
  margin: 200px auto;
  align-items: center;
  text-align: center;
}

.DathBoardItem {
  margin: 10px;
}
