.healthCareContainer {
  width: 100%;
}

.heakthCareProducts {
  width: 85%;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
  margin: 100px auto;
  text-align: center;
}
.healthProductItem {
  margin-top: 100px;
  width: 30%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.healthProductItem .healthImageProduct {
  width: 100%;
  height: 65%;
}
.healthProductItem .healthImageProduct img {
  height: 90%;
  width: 90%;
  margin: 40px auto;
}
.heakthCareTitle {
  color: #0d3166;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  font-size: larger;
}
.btnProduct {
  position: relative;
  color: white;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 50px;
}
.btn.btnProduct {
  z-index: 1;
  position: relative;
  color: white !important;
}

.btnProduct span {
  transition: all 0.3s;
}
.btnProduct::before {
  content: "See More ..";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #0d3166;
  border-bottom-color: #0d3166;
  transform: scale(0.1, 1);
  color: #0d3166;
}
.btnProduct:hover span {
  letter-spacing: 2px;
}
.btnProduct:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.btnProduct::after {
  content: "See More ..";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: #0d3166;
}
.btnProduct:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
  color: white;
}

@media (max-width: 1800px) {
  .healthProductItem {
    width: 400px;
  }
}
