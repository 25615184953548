.productsContainer {
  width: 100%;
  padding: 80px;
  position: relative;
  z-index: 1000;
  background-color: white;
  touch-action: none;
}

.productItem {
  padding-top: 0px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -60;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.firstHalfProductBackground {
  width: 300px;
  height: 200px;
  background-color: rgb(180, 224, 245);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.secondHalfProductBackground {
  width: 300px;
  height: 200px;
  background-color: white;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.productImage {
  width: 250px;
  height: 190px;
  position: relative;
  z-index: 2;
  transform: translateY(-50%);
  margin-left: 20px;
}
.textProduct {
  width: 50%;
  height: 35vh;
  padding: 20px;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(102, 180, 216);
  border-radius: 0px 15px 15px 0px;
  transform: translateY(15%);
}

.titleProduct {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
}
.buttonProductReadMore {
  width: 200px;
  height: 40px;
  transform: translate(-110%, 700%);
  background-color: rgb(102, 180, 216);
  border-radius: 30px;
}
.buttonProductReadMore:hover {
  background-color: rgb(102, 180, 216);
  opacity: 0.8;
}
.definitionProduct {
  margin-top: 20px;
  font-weight: bold;
}
