.partnarContant {
  position: relative;
  z-index: 1;
  width: 98%;
  background: white;
  /* margin-top: 150px; */
  align-items: center;
  margin: 50px auto;
  overflow: hidden;
}
.partnarContant h1 {
  width: 40%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 20px auto;
}

.partnarsItems {
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  width: 80%;
  margin: 0 auto;
}

.partnarsItems {
  display: flex;
  justify-content: space-around;
}
.partnarImage {
  /* margin: 35px 0; */
  /* margin: 40px  */
  height: 290px;
  width: 290px;
  overflow: hidden;
}

.partnarImage img {
  position: relative;
  z-index: -1;
}

.partnarSpace {
  width: 50px;
  height: 10px;
}
.shadowLogo {
  max-width: 300px;
  height: 290px;
  text-align: start;
  background: #084cb3;
  padding-top: 50px;
  opacity: 0.8;
  transform: translate(300px, -285px);
  color: white;
  font-size: 15px;
  border-radius: 8px;
}
.textShadowAlign {
  max-width: 90%;
  font-size: 10px;
  margin: 10px auto;
  text-align: start;
}

.partnarImage:hover img + .shadowLogo {
  transform: translate(0px, -285px);
  transition-duration: 0.5s;
}
.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  color: white;
}
.buttonOnLogoShadow {
  color: white;
  transition: all 0.3s;
  position: relative;
}
.buttonOnLogoShadow a {
  position: relative;
  z-index: 100;
  color: white;
  text-decoration: none;
}
.buttonOnLogoShadow a:hover {
  color: white;
}
.buttonOnLogoShadow span {
  transition: all 0.3s;
}
.buttonOnLogoShadow::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: white;
  border-bottom-color: white;
  transform: scale(0.1, 1);
}
.buttonOnLogoShadow:hover span {
  letter-spacing: 2px;
}
.buttonOnLogoShadow:hover::before {
  opacity: 1;
  transform: scale(1, 1);
}
.buttonOnLogoShadow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.buttonOnLogoShadow:hover::after {
  opacity: 0;
  transform: scale(0.1, 1);
}

.imagePartnarLogoWidth {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 575px) {
  .partLogo {
    width: 250px;
    font-size: 25px;
  }

  .partnarImage {
    margin: 35px 0;
    margin: 0px 60px;
    height: 290px;
    width: 290px;
    overflow: hidden;
  }
  .shadowLogo {
    max-width: 300px;
    max-height: 100%;
    text-align: start;
    background: #084cb3;
    padding-top: 50px;

    opacity: 0.8;
    transform: translate(300px, -300px);
    color: white;
  }
  .textShadowAlign {
    width: 300px;
    margin-left: 15px;
  }
  .textShadowAlign h6 {
    font-size: 15px;
  }
  .shadoTextHead {
    font-size: 20px;
    padding-top: 10px;
  }
}

@media (max-width: 900px) {
  .partnarContant h1 {
    font-size: 40px;
  }
}
@media (max-width: 615px) {
  .partnarContant h1 {
    font-size: 20px;
  }
}
