.noneHealthprod {
  width: 100%;
}
.imageProductNone img {
  width: 100%;
}
.noneHealthContainer {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.noneHealthItem {
  width: 420px;
  margin-top: 30px;

  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 2%;
  padding-bottom: 40px;
}

.noneProdImage img {
  width: 300px;
  height: 300px;
}
.noneProdDetails {
  margin-top: 10px;
  width: 90%;
  margin: 0 auto;
}

.noneProddTitle {
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 30px;
  display: inline-block;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.noneProdDef {
  color: #0d3166;
  text-align: start;
}
