.aboutUsContainer {
  padding-top: 50px;
  width: 1800px;
  /* height: 100%; */
  margin: 0 auto;
  overflow: hidden;
}
.WhoWERAbout {
  max-width: 100%;
}
.aboutUsContainer h1 {
  width: 40%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.aboutUsImage {
  width: 100%;
  height: 100%;
  /* position: absolute; */
}

.WhoWERAbout .shadowOmawi {
  width: 1800px;
  height: 375px;
  background-color: #084cb3;
  position: absolute;
  transform: translateY(-100%);
  opacity: 0.85;
  color: white;
  display: flex;
}
.logoOmawi img {
  height: 375px;
  width: 100%;
}
.aboutUsImage img {
  width: 100%;
  height: 600px;
}

.imagShadow {
  height: 600px;
  width: 900px;
  background-color: #0d3166;
  transform: translate(100%, -100%);
  opacity: 0.85;
  color: white;
  position: absolute;
}

.headOmawi {
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  padding: 5%;
}
.textOmawi {
  font-size: 30px;
  padding: 5%;
  width: 50%;
  line-height: 2;
}
.textHeadAbout {
  font-size: 60px;
  text-align: start;
  line-height: 0.85;
  font-weight: bold;
  padding-left: 100px;
  padding-top: 100px;
}

.textDefAbout {
  font-size: 30px;
  padding: 60px;
  line-height: 2;
}

.imagShadow2 {
  height: 600px;
  width: 900px;
  background-color: #0d3166;
  transform: translateY(-100%);
  opacity: 0.9;
  color: white;
  position: absolute;
}
.visiable {
  display: none;
  font-size: 30px;
  padding: 60px;
  width: 50%;
  line-height: 2;
}
.textDefAboutVisibale {
  display: none;
}
@media (max-width: 1750px) {
  .aboutUsContainer {
    width: 100%;
  }
  .textHeadAbout {
    font-size: 60px;
    padding-left: 50px;
    padding-top: 100px;
  }

  .textDefAbout {
    display: none;
  }
  .textOmawi {
    font-size: 30px;
    padding: 30px;
    display: none;
  }
  .headOmawi {
    font-size: 70px;
    padding: 30px;
  }
  .textHeadAbout {
    font-size: 70px;
  }
  .aboutUsContainer h1 {
    font-size: 70px;
  }

  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 300px;
  }
  .logoOmawi img {
    width: 100%;
    height: 300px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 550px;
  }

  .imagShadow {
    height: 550px;
    width: 50%;
  }
  .imagShadow2 {
    height: 550px;
    width: 50%;
  }
  .visiable {
    display: block;
    font-size: 20px;
    padding: 30px;
    width: 50%;
    line-height: 2;
  }
  .textDefAboutVisibale {
    display: block;
    font-size: 30px;
    padding: 60px;
    line-height: 2;
    font-size: 20px;
  }
}
@media (max-width: 1250px) {
  .textHeadAbout {
    font-size: 60px;
    padding-left: 50px;
    padding-top: 100px;
  }

  .textDefAbout {
    display: none;
  }
  .textOmawi {
    font-size: 20px;
    padding: 30px;
    display: none;
  }
  .headOmawi {
    font-size: 60px;
    padding: 30px;
  }
  .textHeadAbout {
    font-size: 60px;
  }
  .aboutUsContainer h1 {
    font-size: 60px;
  }

  .logoOmawi {
    width: 100%;
    height: 200px;
  }
  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 200px;
  }
  .logoOmawi img {
    width: 100%;
    height: 200px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 450px;
  }

  .imagShadow {
    height: 450px;
    width: 50%;
  }
  .imagShadow2 {
    height: 450px;
    width: 50%;
    background-color: #0d3166;
    transform: translateY(-100%);
    opacity: 0.9;
    color: white;
  }
}

@media (max-width: 900px) {
  .textHeadAbout {
    font-size: 40px;
    padding-left: 50px;
    padding-top: 60px;
  }

  .textDefAbout {
    display: none;
  }
  .textOmawi {
    font-size: 20px;
    padding: 30px;
    display: none;
  }
  .headOmawi {
    font-size: 40px;
    padding: 30px;
  }
  .textHeadAbout {
    font-size: 40px;
  }
  .aboutUsContainer h1 {
    font-size: 40px;
  }

  .logoOmawi {
    width: 100%;
    height: 150px;
  }
  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 150px;
  }
  .logoOmawi img {
    width: 100%;
    height: 150px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 350px;
  }

  .imagShadow {
    height: 350px;
    width: 50%;
  }
  .imagShadow2 {
    height: 350px;
    width: 50%;
    background-color: #0d3166;
    transform: translateY(-100%);
    opacity: 0.9;
    color: white;
  }
  .visiable {
    display: block;
    font-size: 15px;
    padding: 30px;
    width: 70%;
    line-height: 2;
  }
  .textDefAboutVisibale {
    display: block;

    padding: 40px;
    line-height: 2;
    font-size: 15px;
  }
}
@media (max-width: 615px) {
  .textHeadAbout {
    font-size: 20px;
    padding-left: 30px;
    padding-top: 10px;
  }

  .headOmawi {
    font-size: 40px;
    padding: 10px;
  }

  .aboutUsContainer h1 {
    font-size: 20px;
  }

  .logoOmawi {
    width: 100%;
    height: 150px;
  }
  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 150px;
  }
  .logoOmawi img {
    width: 100%;
    height: 150px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 150px;
  }

  .imagShadow {
    height: 150px;
    width: 50%;
  }
  .imagShadow2 {
    height: 150px;
    width: 50%;
  }
  .visiable {
    display: block;
    font-size: 12px;
    padding: 30px;
    width: 90%;
  }
  .textDefAboutVisibale {
    display: block;
    padding: 10px;
    font-size: 8px;
  }
}
