@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Akaya+Telivigala&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  position: absolute;
  z-index: -50;
  width: 100%;
  overflow: hidden;
}

.homepage {
  position: absolute;
  top: 600px;
}

@media (max-width: 575px) {
  .sliderImage {
    margin-top: 88px;
  }
} ;

.partnarContant {
  position: relative;
  z-index: 1;
  width: 98%;
  background: white;
  /* margin-top: 150px; */
  align-items: center;
  margin: 50px auto;
  overflow: hidden;
}
.partnarContant h1 {
  width: 40%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 20px auto;
}

.partnarsItems {
  display: flex;
  flex-wrap: wrap;
  padding-top: 100px;
  width: 80%;
  margin: 0 auto;
}

.partnarsItems {
  display: flex;
  justify-content: space-around;
}
.partnarImage {
  /* margin: 35px 0; */
  /* margin: 40px  */
  height: 290px;
  width: 290px;
  overflow: hidden;
}

.partnarImage img {
  position: relative;
  z-index: -1;
}

.partnarSpace {
  width: 50px;
  height: 10px;
}
.shadowLogo {
  max-width: 300px;
  height: 290px;
  text-align: start;
  background: #084cb3;
  padding-top: 50px;
  opacity: 0.8;
  -webkit-transform: translate(300px, -285px);
          transform: translate(300px, -285px);
  color: white;
  font-size: 15px;
  border-radius: 8px;
}
.textShadowAlign {
  max-width: 90%;
  font-size: 10px;
  margin: 10px auto;
  text-align: start;
}

.partnarImage:hover img + .shadowLogo {
  -webkit-transform: translate(0px, -285px);
          transform: translate(0px, -285px);
  transition-duration: 0.5s;
}
.btn {
  line-height: 50px;
  height: 50px;
  text-align: center;
  width: 250px;
  cursor: pointer;
  color: white;
}
.buttonOnLogoShadow {
  color: white;
  transition: all 0.3s;
  position: relative;
}
.buttonOnLogoShadow a {
  position: relative;
  z-index: 100;
  color: white;
  text-decoration: none;
}
.buttonOnLogoShadow a:hover {
  color: white;
}
.buttonOnLogoShadow span {
  transition: all 0.3s;
}
.buttonOnLogoShadow::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: white;
  border-bottom-color: white;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
}
.buttonOnLogoShadow:hover span {
  letter-spacing: 2px;
}
.buttonOnLogoShadow:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.buttonOnLogoShadow::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: rgba(255, 255, 255, 0.1);
}
.buttonOnLogoShadow:hover::after {
  opacity: 0;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
}

.imagePartnarLogoWidth {
  max-width: 100%;
  max-height: 100%;
}

@media (max-width: 575px) {
  .partLogo {
    width: 250px;
    font-size: 25px;
  }

  .partnarImage {
    margin: 35px 0;
    margin: 0px 60px;
    height: 290px;
    width: 290px;
    overflow: hidden;
  }
  .shadowLogo {
    max-width: 300px;
    max-height: 100%;
    text-align: start;
    background: #084cb3;
    padding-top: 50px;

    opacity: 0.8;
    -webkit-transform: translate(300px, -300px);
            transform: translate(300px, -300px);
    color: white;
  }
  .textShadowAlign {
    width: 300px;
    margin-left: 15px;
  }
  .textShadowAlign h6 {
    font-size: 15px;
  }
  .shadoTextHead {
    font-size: 20px;
    padding-top: 10px;
  }
}

@media (max-width: 900px) {
  .partnarContant h1 {
    font-size: 40px;
  }
}
@media (max-width: 615px) {
  .partnarContant h1 {
    font-size: 20px;
  }
}

.aboutUsContainer {
  padding-top: 50px;
  width: 1800px;
  /* height: 100%; */
  margin: 0 auto;
  overflow: hidden;
}
.WhoWERAbout {
  max-width: 100%;
}
.aboutUsContainer h1 {
  width: 40%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.aboutUsImage {
  width: 100%;
  height: 100%;
  /* position: absolute; */
}

.WhoWERAbout .shadowOmawi {
  width: 1800px;
  height: 375px;
  background-color: #084cb3;
  position: absolute;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0.85;
  color: white;
  display: flex;
}
.logoOmawi img {
  height: 375px;
  width: 100%;
}
.aboutUsImage img {
  width: 100%;
  height: 600px;
}

.imagShadow {
  height: 600px;
  width: 900px;
  background-color: #0d3166;
  -webkit-transform: translate(100%, -100%);
          transform: translate(100%, -100%);
  opacity: 0.85;
  color: white;
  position: absolute;
}

.headOmawi {
  font-size: 60px;
  font-weight: bold;
  line-height: 1;
  padding: 5%;
}
.textOmawi {
  font-size: 30px;
  padding: 5%;
  width: 50%;
  line-height: 2;
}
.textHeadAbout {
  font-size: 60px;
  text-align: start;
  line-height: 0.85;
  font-weight: bold;
  padding-left: 100px;
  padding-top: 100px;
}

.textDefAbout {
  font-size: 30px;
  padding: 60px;
  line-height: 2;
}

.imagShadow2 {
  height: 600px;
  width: 900px;
  background-color: #0d3166;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  opacity: 0.9;
  color: white;
  position: absolute;
}
.visiable {
  display: none;
  font-size: 30px;
  padding: 60px;
  width: 50%;
  line-height: 2;
}
.textDefAboutVisibale {
  display: none;
}
@media (max-width: 1750px) {
  .aboutUsContainer {
    width: 100%;
  }
  .textHeadAbout {
    font-size: 60px;
    padding-left: 50px;
    padding-top: 100px;
  }

  .textDefAbout {
    display: none;
  }
  .textOmawi {
    font-size: 30px;
    padding: 30px;
    display: none;
  }
  .headOmawi {
    font-size: 70px;
    padding: 30px;
  }
  .textHeadAbout {
    font-size: 70px;
  }
  .aboutUsContainer h1 {
    font-size: 70px;
  }

  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 300px;
  }
  .logoOmawi img {
    width: 100%;
    height: 300px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 550px;
  }

  .imagShadow {
    height: 550px;
    width: 50%;
  }
  .imagShadow2 {
    height: 550px;
    width: 50%;
  }
  .visiable {
    display: block;
    font-size: 20px;
    padding: 30px;
    width: 50%;
    line-height: 2;
  }
  .textDefAboutVisibale {
    display: block;
    font-size: 30px;
    padding: 60px;
    line-height: 2;
    font-size: 20px;
  }
}
@media (max-width: 1250px) {
  .textHeadAbout {
    font-size: 60px;
    padding-left: 50px;
    padding-top: 100px;
  }

  .textDefAbout {
    display: none;
  }
  .textOmawi {
    font-size: 20px;
    padding: 30px;
    display: none;
  }
  .headOmawi {
    font-size: 60px;
    padding: 30px;
  }
  .textHeadAbout {
    font-size: 60px;
  }
  .aboutUsContainer h1 {
    font-size: 60px;
  }

  .logoOmawi {
    width: 100%;
    height: 200px;
  }
  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 200px;
  }
  .logoOmawi img {
    width: 100%;
    height: 200px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 450px;
  }

  .imagShadow {
    height: 450px;
    width: 50%;
  }
  .imagShadow2 {
    height: 450px;
    width: 50%;
    background-color: #0d3166;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0.9;
    color: white;
  }
}

@media (max-width: 900px) {
  .textHeadAbout {
    font-size: 40px;
    padding-left: 50px;
    padding-top: 60px;
  }

  .textDefAbout {
    display: none;
  }
  .textOmawi {
    font-size: 20px;
    padding: 30px;
    display: none;
  }
  .headOmawi {
    font-size: 40px;
    padding: 30px;
  }
  .textHeadAbout {
    font-size: 40px;
  }
  .aboutUsContainer h1 {
    font-size: 40px;
  }

  .logoOmawi {
    width: 100%;
    height: 150px;
  }
  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 150px;
  }
  .logoOmawi img {
    width: 100%;
    height: 150px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 350px;
  }

  .imagShadow {
    height: 350px;
    width: 50%;
  }
  .imagShadow2 {
    height: 350px;
    width: 50%;
    background-color: #0d3166;
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 0.9;
    color: white;
  }
  .visiable {
    display: block;
    font-size: 15px;
    padding: 30px;
    width: 70%;
    line-height: 2;
  }
  .textDefAboutVisibale {
    display: block;

    padding: 40px;
    line-height: 2;
    font-size: 15px;
  }
}
@media (max-width: 615px) {
  .textHeadAbout {
    font-size: 20px;
    padding-left: 30px;
    padding-top: 10px;
  }

  .headOmawi {
    font-size: 40px;
    padding: 10px;
  }

  .aboutUsContainer h1 {
    font-size: 20px;
  }

  .logoOmawi {
    width: 100%;
    height: 150px;
  }
  .WhoWERAbout .shadowOmawi {
    width: 100%;
    height: 150px;
  }
  .logoOmawi img {
    width: 100%;
    height: 150px;
  }
  .aboutUsImage img {
    width: 100%;
    height: 150px;
  }

  .imagShadow {
    height: 150px;
    width: 50%;
  }
  .imagShadow2 {
    height: 150px;
    width: 50%;
  }
  .visiable {
    display: block;
    font-size: 12px;
    padding: 30px;
    width: 90%;
  }
  .textDefAboutVisibale {
    display: block;
    padding: 10px;
    font-size: 8px;
  }
}

.careerCareer {
  padding-top: 50px;
}
.careerCareer h1 {
  width: 40%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.careerText {
  color: #fff;
  background-color: #0d3166;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px;
  border-top: 1px solid #0d3166;
  border-bottom: 1px solid #0d3166;
}
.formWhite {
  width: 70%;
  margin: 30px auto;
  /* height: 700px; */
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 4%;
}
.formCareer {
  width: 40%;
  margin: 0 auto;
  background-color: black;
  opacity: 0.7;
  -webkit-transform: translateY(-200%);
          transform: translateY(-200%);
}
.inputCarer {
  width: 70%;
  height: 50px;
  border-radius: 5px;
  margin: 10px auto;
  border: none;
  border-bottom: 2px solid gray;
  outline: none;
}

.inputAera {
  width: 70%;
  height: 200px;
  border-radius: 5px;
  margin: 10px auto;
  border: none;
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  outline: none;
}

.btanCarer {
  position: relative;
  color: white;
  text-decoration: none;
  margin-bottom: 50px;
}
.btn.btanCarera {
  z-index: 1;
  position: relative;
  color: white !important;
}

.btanCarer span {
  transition: all 0.3s;
}
.btanCarer::before {
  color: #fff;
  content: "Submit";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #0d3166;
  border-bottom-color: #0d3166;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
  color: #0d3166;
}
.btanCarer:hover span {
  letter-spacing: 2px;
}
.btanCarer:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.btanCarer::after {
  color: #fff;
  content: "Submit";
  position: absolute;
  padding: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: #0d3166;
}
.btanCarer:hover::after {
  opacity: 0;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
}

@media (max-width: 900px) {
  .formWhite {
    width: 100%;
  }

  .careerCareer h1 {
    font-size: 40px;
  }
}
@media (max-width: 615px) {
  .careerCareer h1 {
    font-size: 30px;
  }
  .inputCarer {
    width: 90%;
  }
}

.form {
  background-color: white;
  align-items: center;
  text-align: center;
  display: block;
  width: 25%;
  margin: 0 auto;
  border-radius: 5px;
}
.LogIn {
  width: 100%;
  height: 700px;
  margin-top: 200px;
}

.inputAuth {
  margin-bottom: 30px;
}
.headForm {
  margin-bottom: 60px;
}

.textEaraContainer {
  display: block;
  width: 80%;
  margin: 200px auto;
  align-items: center;
  text-align: center;
}

.DathBoardItem {
  margin: 10px;
}

.dashBoardContainer {
  width: 100%;
}
.dashBoardContainer img {
  width: 100%;
}

.productsContainer {
  width: 100%;
  padding: 80px;
  position: relative;
  z-index: 1000;
  background-color: white;
  touch-action: none;
}

.productItem {
  padding-top: 0px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -60;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.firstHalfProductBackground {
  width: 300px;
  height: 200px;
  background-color: rgb(180, 224, 245);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.secondHalfProductBackground {
  width: 300px;
  height: 200px;
  background-color: white;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.productImage {
  width: 250px;
  height: 190px;
  position: relative;
  z-index: 2;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-left: 20px;
}
.textProduct {
  width: 50%;
  height: 35vh;
  padding: 20px;
  background-color: rgb(241, 241, 241);
  border: 1px solid rgb(102, 180, 216);
  border-radius: 0px 15px 15px 0px;
  -webkit-transform: translateY(15%);
          transform: translateY(15%);
}

.titleProduct {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
}
.buttonProductReadMore {
  width: 200px;
  height: 40px;
  -webkit-transform: translate(-110%, 700%);
          transform: translate(-110%, 700%);
  background-color: rgb(102, 180, 216);
  border-radius: 30px;
}
.buttonProductReadMore:hover {
  background-color: rgb(102, 180, 216);
  opacity: 0.8;
}
.definitionProduct {
  margin-top: 20px;
  font-weight: bold;
}

.productPageContainer {
  /* background-color: rgb(235, 250, 249); */
  padding-top: 150px;
}

.detContainer img {
  width: 100%;
}

.imageDetails {
  margin: 30px auto;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 4%;
  padding: 2%;
  position: absolute;
  right: 20px;
}
.imageDetails img {
  width: 400px;
  height: 400px;
}
.detailsContainer {
  width: 95%;
  margin: 10px auto;
}

.titleDetails {
  width: 20%;
  display: inline-block;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 25px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.defDetails {
  width: 60%;
  color: #0d3166;
  line-height: 2;
  text-transform: uppercase;
  margin-right: 20px;
}

@media (max-width: 1155px) {
  .defDetails {
    width: 65%;
  }
  .imageDetails img {
    width: 300px;
    height: 300px;
  }
}
@media (max-width: 1037px) {
  .defDetails {
    width: 55%;
  }
}
@media (max-width: 800px) {
  .defDetails {
    width: 100%;
  }
  .imageDetails {
    position: relative;
    right: 0;
  }
  .imageDetails img {
    width: 250px;
    height: 250px;
  }
}

.imagePharma {
  width: 100%;
}
.imagePharma img {
  width: 100%;
}

.farmaContainer {
  width: 80%;
  margin: 10px auto;
}
.farmaContainer h2 {
  width: 100%;
  margin: 10px auto;
  color: #0d3166;
  font-size: 18px;
  line-height: 2;
  font-weight: normal;
  text-transform: uppercase;
}
.pharmaBody .farmaContainer h1 {
  width: 50%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 50px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.pharmaText {
  color: #fff;
  background-color: #0d3166;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px;
  border-top: 1px solid #0d3166;
  border-bottom: 1px solid #0d3166;
}
.formWhite {
  width: 70%;
  margin: 30px auto;

  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 4%;
}

.inputPharma {
  width: 70%;
  height: 50px;
  border-radius: 5px;
  margin: 10px auto;
  border: none;
  background-color: #fff;
  border-bottom: 2px solid gray;
  outline: none;
}

.inputAera {
  width: 70%;
  height: 150px;
  border-radius: 5px;
  margin: 10px auto;
  border: none;
  border-bottom: 2px solid gray;
  border-left: 2px solid gray;
  outline: none;
}

.btanCarer {
  position: relative;
  color: white;
  text-decoration: none;
  margin-bottom: 50px;
}
.btn.btanCarera {
  z-index: 1;
  position: relative;
  color: white !important;
}

.btanCarer span {
  transition: all 0.3s;
}
.btanCarer::before {
  content: "Submit";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #0d3166;
  border-bottom-color: #0d3166;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
  color: #0d3166;
}
.btanCarer:hover span {
  letter-spacing: 2px;
}
.btanCarer:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.btanCarer::after {
  content: "Submit";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: #0d3166;
}
.btanCarer:hover::after {
  opacity: 0;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
  color: white;
}

@media (max-width: 1400px) {
  .formWhite {
    width: 100%;
  }
  .farmaContainer h2 {
    width: 90%;
  }

  .pharmaBody .farmaContainer h1 {
    font-size: 30px;
  }
}
@media (max-width: 972px) {
  .formWhite {
    width: 100%;
  }
  .farmaContainer {
    width: 90%;
  }

  .pharmaBody .farmaContainer h1 {
    font-size: 30px;
  }
  .inputPharma,
  .inputAera {
    width: 90%;
  }
}
@media (max-width: 615px) {
  .farmaContainer {
    width: 100%;
  }
  .pharmaBody .farmaContainer h1 {
    font-size: 20px;
    width: 60%;
  }
  .imagePharma img {
    margin-top: 100px;
  }
}

.healthCareContainer {
  width: 100%;
}

.heakthCareProducts {
  width: 85%;
  justify-content: space-around;
  flex-wrap: wrap;
  display: flex;
  margin: 100px auto;
  text-align: center;
}
.healthProductItem {
  margin-top: 100px;
  width: 30%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.healthProductItem .healthImageProduct {
  width: 100%;
  height: 65%;
}
.healthProductItem .healthImageProduct img {
  height: 90%;
  width: 90%;
  margin: 40px auto;
}
.heakthCareTitle {
  color: #0d3166;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 20px;
  font-size: larger;
}
.btnProduct {
  position: relative;
  color: white;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 50px;
}
.btn.btnProduct {
  z-index: 1;
  position: relative;
  color: white !important;
}

.btnProduct span {
  transition: all 0.3s;
}
.btnProduct::before {
  content: "See More ..";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-bottom-style: solid;
  border-top-color: #0d3166;
  border-bottom-color: #0d3166;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
  color: #0d3166;
}
.btnProduct:hover span {
  letter-spacing: 2px;
}
.btnProduct:hover::before {
  opacity: 1;
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}
.btnProduct::after {
  content: "See More ..";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 0.3s;
  background-color: #0d3166;
}
.btnProduct:hover::after {
  opacity: 0;
  -webkit-transform: scale(0.1, 1);
          transform: scale(0.1, 1);
  color: white;
}

@media (max-width: 1800px) {
  .healthProductItem {
    width: 400px;
  }
}

.healthHeader h1 {
  width: 50%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .healthHeader h1 {
    font-size: 40px;
  }
}
@media (max-width: 615px) {
  .healthHeader h1 {
    font-size: 20px;
  }
  .healthHeader img {
    margin-top: 100px;
  }
}

.noneHealthprod {
  width: 100%;
}
.imageProductNone img {
  width: 100%;
}
.noneHealthContainer {
  text-align: center;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.noneHealthItem {
  width: 420px;
  margin-top: 30px;

  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
  padding-top: 2%;
  padding-bottom: 40px;
}

.noneProdImage img {
  width: 300px;
  height: 300px;
}
.noneProdDetails {
  margin-top: 10px;
  width: 90%;
  margin: 0 auto;
}

.noneProddTitle {
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 30px;
  display: inline-block;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
}
.noneProdDef {
  color: #0d3166;
  text-align: start;
}

.noneHealthText h1 {
  width: 50%;
  text-align: center;
  padding: 10px;
  font-weight: bolder;
  font-size: 60px;
  color: #0d3166;
  border-top: 3px solid #0d3166;
  border-bottom: 3px solid #0d3166;
  margin: 40px auto;
  text-transform: uppercase;
}

@media (max-width: 900px) {
  .noneHealthText h1 {
    font-size: 40px;
  }
}
@media (max-width: 615px) {
  .noneHealthText h1 {
    font-size: 20px;
  }
  .noneHealthText img {
    margin-top: 100px;
  }
}

.footerContainer {
  margin-top: 200px;
  width: 100%;
  height: 600px;
  background-color: white;
  font-family: "Montserrat", sans-serif;

  position: relative;
  /* z-index: 2; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19); */
}
.subFooter {
  width: 100%;
  height: 450px;
  border-radius: 0px 50% 0px 0px;
  position: absolute;
  /* z-index: 3; */
  bottom: 0;
  background-color: #084cb3;
  color: white;
  display: flex;
  padding-top: 80px;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  /* border: 2px solid #c7b746; */
  box-shadow: 0px 10px 30px 0px black inset;
}

.FootercontactUs {
  text-align: start;
}

.inputProblem {
  width: 250px;
  height: 45px;
  border-radius: 2px;
  margin-right: 5px;
}

.copyRight {
  width: 90%;
  border-top: 2px solid white;
  margin: 0px auto;
  text-align: center;
  color: white;
  position: absolute;
  bottom: 0;
  left: 5%;
  padding: 20px;
  z-index: 2;
}

.FooterFollowUs {
  text-align: center;
  display: block;
}
.socialMedia {
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-right: 20px;
}
.FooterFollowUs a {
  color: white;
}
.footerLogo {
  position: relative;
  margin-top: 100px;
}

@media (max-width: 1100px) {
  .footerContainer {
    height: 950px;
  }
  .subFooter {
    height: 850px;
  }
  .footerLogo {
    margin-bottom: 300px;
  }
}
@media (max-width: 480px) {
  .footerContainer {
    height: 1000px;
  }
  .subFooter {
    height: 1050px;
    border-radius: 0px 30% 0px 0px;
  }
}

.headerContainer {
  text-align: right;

  /* color: aqua; */
}
.settingsValue {
  background: linear-gradient(to right, #084cb3, #084cb3, #fff);
  padding-right: 30px;
  stroke: 2px solid #fff;
  width: 100%;
  text-transform: uppercase;
  position: fixed;
  z-index: 101;
}
.headerContainer h3 {
  /* font-family: "Montserrat" sans-serif; */
  font-size: 20px;
  /* color: #0d3166; */
  color: #084cb3;
  font-family: "Akaya Telivigala", cursive;
  top: 0;
  margin: 0;
  padding: 3px 0px;
}

.headerBar {
  width: 100%;
  display: flex;
  position: fixed;
  top: 29px;
  justify-content: space-around;
  background-color: transparent;
  padding: 5px;
  z-index: 100;
}
.headerVisColor {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 2px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
}
.block .navItem .naveItemHover a {
  color: #084cb3;
}
.navItem .naveItemHover a:hover {
  border-bottom: transparent;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.actveMenu {
  border-bottom: 2px solid #084cb3;
  font-weight: bold;
}

.whiteActiveMenu {
  border-bottom: 2px solid white;
  font-weight: bold;
}

.header {
  width: 90%;
  margin: 0 auto;
  display: flex;
}
.mobile {
  position: absolute;
  right: 30px;
  color: white !important;
  display: none;
}

.mobile button {
  background: white;
  border: none;
  outline: none;
}

/*menu header*/

.menu {
  width: 100%;
  color: black;
  margin: 0 auto;
  background-color: transparent;
  display: flex;
  justify-content: space-around;
}
.subMenu {
  margin: 0 auto;
  display: block;
  font-size: 15px;
  margin: 0 auto;
  position: absolute;
  top: 50%;

  -webkit-transform: translateY(-50%);

          transform: translateY(-50%);
}
.navItem {
  width: 150px;
  font-size: large;
  text-align: center;
}
.naveItemHover {
  padding: 4px 4px;
}

.FlexNon {
  display: flex;
  padding: auto;
}
.blue {
  color: #084cb3;
}
.white {
  color: white;
}
.white:hover {
  color: white;
}
.blueImageSmall {
  display: none;
}
@media (max-width: 1300px) {
  .blueImageSmall img {
    margin-right: 90%;
  }
  .settingsValue {
    background: linear-gradient(to right, #084cb3, #fff);
  }
  .logoBigImage {
    display: none;
  }
  .blueImageSmall {
    display: block;
  }
  .headerBar {
    background-color: white;
  }
  .mobile {
    display: block;
    z-index: 1;
  }
  .subMenu {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 700px;
    margin: auto;
    background-color: white;
    opacity: 0.95;
    padding-top: 30px;
    position: absolute;
    top: 0%;
    -webkit-transform: translateY(-0%);
            transform: translateY(-0%);
  }
  .header {
    display: block;
  }

  .navItem {
    width: 350px;
    padding: 20px 0px;
    color: white;
  }
  .FlexNon {
    display: none;
  }
  .menu {
    height: 0px;
  }
  .menu .subMenu .navItem .naveItemHover a {
    /* color: white; */
    font-size: large;
    font-weight: bold;
  }
  .closebigIcon {
    position: relative;
    top: 12px;
    border-radius: 10px;
    background: #084cb3;
  }
}
@media (max-width: 575px) {
  .headerContainer h3 {
    font-size: 13px;
    padding: 10px;
  }
  .mobile {
    right: 0px;
    top: 0px;
    margin: 3px;
  }
  .headerBar {
    margin-top: 0px;
  }
  .logo {
    width: 50px;
    height: 50px;
  }
  .menu {
    height: 0px;
  }
}

.LogoClose {
  visibility: hidden;
}

.closeOpenIcon {
  border: none;
  outline: none;
}

.closebigIcon {
  position: relative;
  top: 13px;
  border-radius: 10px;
  background: #084cb3;
}

